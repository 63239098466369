/**
 * Libs (rendo disponibili globalmente)
 */
import Utilities from "./lib/Utilities";
import Form from "./lib/Form";
window.Form = Form;
window.Utilities = Utilities;

var debugMode = window.ENV == 'local';

/**
 * DOM ready
 */
$(function() {

});


/**
 * Load
 */
$(window).on("load", function() {

    /**
     * Carico i css
     */
    /*  $("head").find('.css-defer').each(function(i) {

        LoaderManager.addEvent("css_loading-"+i);

        let stylesheet = loadCSS( $(this).attr("href") );
        onloadCSS( stylesheet, function() {
            //console.log( stylesheet);
            LoaderManager.eventComplete("css_loading-"+i);
        });
  
    }); */
    

    
    /**
     * L'evento "loadermanager-page_loaded" viene lanciato quando la coda
     * di LoaderManager è stata svuotata
     */
    $(document).on("loadermanager-page_loaded", function() {


        setTimeout(function() {
            //init menu
            menuInit();

            //inizializzo lenis scroll
            lenisInit();

        
            initSeguiMouse();

            initAnchors();

            pageEnter();

            initParallax();

            $('body').removeClass('loading');

            //bottoni per copiare un codice
            initCopyButtons();

        }, 50);



    });


    /**
     * Configurazione globale componenti uikit
    
    (function() {
        // container false per modal, evita duplicazione elemento in seguito a caricamenti asincroni (barba.js)
        UIkit.mixin({
            data: {
                container: false,
            }
        }, 'modal');
    })();
 */

    /**
     * Update necessari dopo caricamenti livewire
    */
    Livewire.hook('message.processed', function () {
      
        // scroll a eventuale messaggio di errore form
        scrollToFormError();

    });


    // scroll a eventuale messaggio di errore form, al caricamento!
    scrollToFormError();
        
    
    /**
     * Fine caricamento
     */
    LoaderManager.eventComplete("site_loading");


    /**
     * Init di lazysizes
     */
    document.addEventListener('lazyloaded', function (e) {
        e.target.classList.remove('loading');
    });
    lazySizes.init();

});


/**
 * Scroll al primo messaggio di errore di un form
 */
function scrollToFormError()
{
    // se ho dei messaggi di validazione di un form, scrollo al primo messaggio
    if (!$('form .field__error').length) {
        return;
    }

}


function initAnchors(){
    //anchor link
    document.querySelectorAll('a[href^="#"].anchor').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            let offset = parseInt(this.getAttribute('data-anchor-offset')) || 0;
            lenis.scrollTo(this.getAttribute('href'), { offset: -offset });
        });
    })
}



/**
 * Funzione di entrata pagina
 */
function pageEnter(callback){
    //animazione in entrata della pagina (uscita del loader)
    $( "#page_loader" ).animate({opacity: "0"}, 200, function() {

        // porto sotto per poter interagire con pagina
        $("#page_loader").css({
            zIndex: -1,
        });
        if (callback && typeof(callback) == 'function') {
            callback();
        }
    })
    

}

function menuInit(){
    //bottone prodotti (visibile su desk)
    document.getElementById('btnProdotti').addEventListener('click', function() {
        toggleClass('menuProdotti');
    });
    //bottone menu 
    document.getElementById('btnMenu').addEventListener('click', function() {
        toggleClass('menuSito');
    });

    //click su background menu
    document.getElementById('bgMenu').addEventListener('click', function() {
        toggleClass();
    });

    //click su ricerca
    document.getElementById('search-input').addEventListener('focus', () => {
        toggleClass('menuRicerca');
    });


    //rollover sulle categorie sottomenu prodotti

    const categorie = document.querySelectorAll("#menuCategories li");
    const imgCategoria = document.querySelector("#menuImgCategory img");

    if (!imgCategoria) return; // Controllo se l'immagine esiste
    // Se touch
    if ("ontouchstart" in window || navigator.maxTouchPoints > 0) return;

    categorie.forEach(li => {
        li.addEventListener("mouseenter", function () {
            const newImage = this.getAttribute("data-img");
            //converto in url assoluto, visto che imgCategoria.src restituisce un url assoluto
            const absoluteNewImage = new URL(newImage, window.location.origin).href;
            
            if (absoluteNewImage && imgCategoria.src !== absoluteNewImage) {
                // Effetto fade out
                imgCategoria.style.transition = "opacity 0.2s ease";
                imgCategoria.style.opacity = "0";

                // Crea un oggetto immagine temporaneo per caricare la nuova immagine
                const tempImg = new Image();
                tempImg.src = absoluteNewImage;

                tempImg.onload = () => {
                    // Dopo che l'immagine è stata caricata, cambia src e ripristina opacity
                    setTimeout(() => {
                        imgCategoria.src = absoluteNewImage;
                        imgCategoria.style.opacity = "1"; // Effetto fade in
                    }, 200);
                };
            }
        });
    });



}

//apre e chiude i sottomenu
function toggleClass(classToAdd) {
    const body = document.body;
    if(classToAdd == 'menuRicerca'){
        //click apertura ricerca (primo o secondo click)
        //se serve, chiude gli altri 
        body.classList.remove('menuProdotti', 'menuSito'); 
        body.classList.add(classToAdd); 
    } else if(classToAdd !== null && !body.classList.contains(classToAdd)){
        //primo click su menuSito o menuProdotti
        //chiude tutto, poi apre l'item cliccato
        body.classList.remove('menuProdotti', 'menuSito', 'menuRicerca'); 
        body.classList.add(classToAdd); 

        //ferma lenis
        lenis.stop();
    } else {
        //secondo click su stesso elemento, o click fuori da tutto
        //chiude tutto
        body.classList.remove('menuProdotti', 'menuSito', 'menuRicerca'); 
        lenis.start();
    }

    //cliccato fuori
    if(classToAdd === undefined){
        lenis.start();
    }
}

function lenisInit(){
    
    const lenis = new Lenis({
        duration: 1.2,
        //easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
        direction: "vertical",
        gestureDirection: "vertical",
        smooth: false,
        smoothTouch: false,
        touchMultiplier: 2,
    });

    
    // lenis.on('scroll', (e) => {
    //     var dir = e.direction;
    //     var scroll = e.scroll;
    //     if(dir==1) { //scroll down
    //         $('body').removeClass("menuOpen");
    //         if(scroll > 100){
    //             $('body').addClass("scrolling");
    //         }
    //     } else{
    //         //scroll up
    //         if(scroll < 100){
    //             $('body').removeClass("scrolling"); 
    //         } 
    //     }
    // })
    

    function raf(time) {
        lenis.raf(time)
        requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)

    window.lenis = lenis;
}



function initParallax(){
    //Per applicare l'effetto:
    //aggiungere l'attributo > parallax=""
    //poi passare le opzioni (con stato iniziale e stato finale) es > parallax="scale:1, 1.5; opacity:0,1"
    //come opzioni: scale - y - opacity
    //opzionale > se serve fare riferimento ad un div padre, 'wrappare' con un elemento con class="c-parallax-wrap"  
    
    // Seleziona tutti gli elementi con l'attributo 'parallax'
    const elements = document.querySelectorAll('[parallax]');

    // Applica l'animazione a ciascun elemento
    elements.forEach((el) => {
        // Leggi l'attributo 'parallax' e analizza le opzioni
        const parallaxOptions = el.getAttribute('parallax');
        const options = {};
        const scrollTriggerOptions = {
            start: 'top bottom', // Default
            end: 'bottom top',   // Default
        };

        // Converte la stringa delle opzioni in un oggetto
        parallaxOptions.split(';').forEach((option) => {
            const [key, value] = option.split(':').map((item) => item.trim());
            if (value) {
                // Se la chiave è 'start' o 'end', salva nei valori dello scrollTrigger
                if (key === 'start' || key === 'end') {
                    scrollTriggerOptions[key] = value; // Assegna il valore personalizzato
                } else {
                    // Altrimenti, è una proprietà come scale, y, opacity, ecc.
                    const [start, end] = value.split(',').map((item) => item.trim());
                    options[key] = {
                        start: start ? (isNaN(start) ? start : parseFloat(start)) : null,
                        end: end ? (isNaN(end) ? end : parseFloat(end)) : null,
                    };
                }
            }
        });

        // Trova il wrapper o utilizza l'elemento corrente come trigger
        const wrapper = el.closest('.c-parallax-wrap') || el;

        // Applica le opzioni a GSAP con fallback ai valori di default
        gsap.fromTo(
            el,
            // Stato iniziale
            {
                scale: options.scale?.start ?? 1, // Default 1
                y: options.y?.start ?? '0px',    // Default '0px'
                opacity: options.opacity?.start ?? 1, // Default 1
            },
            // Stato finale
            {
                scale: options.scale?.end ?? 1, // Default 1
                y: options.y?.end ?? '0px',    // Default '0px'
                opacity: options.opacity?.end ?? 1, // Default 1
                ease: 'none',
                scrollTrigger: {
                    trigger: wrapper,       // Usa il wrapper come trigger
                    start: scrollTriggerOptions.start, // Valore da HTML o default
                    end: scrollTriggerOptions.end,     // Valore da HTML o default
                    scrub: true,            // Sincronizza con lo scroll
                },
            }
        );
    });



}

//icona che segue il mouse
var cursor;
function initSeguiMouse() {
    if ($('html').hasClass('no-touch')) {
        //rollover con icona che segue il cursore 
        cursor = $(".myCursor");
        var $mouseX = 0,
            $mouseY = 0;
        var $xp = 0,
            $yp = 0;

        $(document).on("mousemove", function (e) {
            $mouseX = e.clientX + 25;
            $mouseY = e.clientY + 25;
        });

        var $loop = setInterval(function () {
            $xp += (($mouseX - $xp) / 5);
            $yp += (($mouseY - $yp) / 5);
            cursor.css({ left: $xp + 'px', top: $yp + 'px' });
            
        }, 30);

        updateSeguiMouse();

    }
}
function updateSeguiMouse() {

    cursor.removeClass("drag cursor-discover cursor-gallery cursor-scroll cursor-next cursor-prev");


    if ($('html').hasClass('no-touch')) {
        
       
        //drag
        $(".hover-copy").on("mouseenter", function () {
            cursor.addClass("cursor-copy");
        });
        $(".hover-copy").on("mouseleave", function () {
            cursor.removeClass("cursor-copy");
        });


        //discover
        $(".hover-discover").on("mouseenter", function () {
            cursor.addClass("cursor-discover");
        });
        
        $(".hover-discover").on("mouseleave", function () {
            cursor.removeClass("cursor-discover");
        });


        //download
        $(".hover-download").on("mouseenter", function () {
            cursor.addClass("cursor-download");
        });
        $(".hover-download").on("mouseleave", function () {
            cursor.removeClass("cursor-download");
        });

        //next
        $(".hover-next").on("mouseenter", function () {
            cursor.addClass("cursor-next");
        });
        $(".hover-next").on("mouseleave", function () {
            cursor.removeClass("cursor-next");
        });

        //prev
        $(".hover-prev").on("mouseenter", function () {
            cursor.addClass("cursor-prev");
        });
        $(".hover-prev").on("mouseleave", function () {
            cursor.removeClass("cursor-prev");
        });
  
        //comingSoon
        $(".hover-comingSoon").on("mouseenter", function () {
            cursor.addClass("cursor-comingSoon");
        });
        $(".hover-comingSoon").on("mouseleave", function () {
            cursor.removeClass("cursor-comingSoon");
        });

    }
}



//funzioni per copiare il testo
function copyToClipboard(element) {
   
    const codeSpan = element.querySelector('span.c-txt');

    // Verifica che l'elemento span, con il testo da copiare esista, prima di procedere
    if (codeSpan) {
        const codeText = codeSpan.innerText;
        
        
        // Copia il testo negli appunti
        navigator.clipboard.writeText(codeText).then(() => {
            // Cambia il colore del testo per confermare la copia
            codeSpan.classList.add('copiato');
            
            // Rimuovi il colore dopo 1 secondo
            setTimeout(() => {
                codeSpan.classList.remove('copiato');
            }, 1000);
        }).catch(err => {
            console.error('Errore durante la copia:', err);
        });
    }
}

// Funzione per aggiungere l'evento di click sui bottoni copia
function initCopyButtons() {

    $(document).on('click', '.copy-btn', function() {
        copyToClipboard(this);
    });
}

